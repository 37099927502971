<template>
    <div>
        <el-tooltip effect="dark" :content="content" placement="right">
            <i class="fa-light fa-circle-info" />
        </el-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        content: {
            type: String,
            default: "",
        },
    },
};
</script>
